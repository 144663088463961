import * as THREE from "three";

import "./common/module.js";
import cursor from "./common/cursor.js";
import config from "./config/main.js";
import data from "../data/main.json";

const scales = [
  {
    radius: 4.0,
    iSize: 15,
    offset: 0.0,
  },
  {
    radius: 4.25,
    iSize: 20,
    offset: 0.0,
  },
  {
    radius: 5.0,
    iSize: 25,
    offset: 0.1,
  },
];
const maxScale = scales.length - 1;
let scaleCurrent = 0;

const container = document.getElementById("container");
const controlPlus = document.querySelector(".control--plus");
const controlMinus = document.querySelector(".control--minus");

init();

function init() {
  window.videoapp.setup(
    THREE,
    config,
    data.items,
    container
  );
  
  cursor();
  setControls();
}

function setControls() {
  controlPlus.addEventListener("click", scalePrev);
  controlMinus.addEventListener("click", scaleNext);

  if (scaleCurrent === 0) {
    controlPlus.setAttribute('disabled', true);
  } else if (scaleCurrent === maxScale) {
    controlMinus.setAttribute('disabled', true);
  }
}

function scaleNext() {
  if (scaleCurrent < maxScale) {
    scaleCurrent++;
    changeScale();
  }
}

function scalePrev() {
  if (scaleCurrent > 0) {
    scaleCurrent--;
    changeScale();
  }
}

function changeScale() {
  if (scaleCurrent === 0) {
    controlPlus.disabled = true;
    controlMinus.disabled = false;
  } else if (scaleCurrent === maxScale) {
    controlPlus.disabled = false;
    controlMinus.disabled = true;
  } else {
    controlPlus.disabled = false;
    controlMinus.disabled = false;
  }

  window.videoapp.updateScale({
    radius: scales[scaleCurrent].radius,
    iSize: scales[scaleCurrent].iSize,
    offset: scales[scaleCurrent].offset,
  });
}