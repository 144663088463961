
import device from "current-device";
import gsap from "gsap";

let isMobile = device.mobile() || device.tablet();
const container = document.getElementById("container");

let cursor = null;
let cursorCircle = null;

export default function init() {
  if (!isMobile) {
    setCursor();
    container.addEventListener("mousemove", onMouseMove);
    container.addEventListener("mousedown", onMouseDown);
    container.addEventListener("mouseup", onMouseUp);
    container.addEventListener("mouseenter", onMouseEnter);
    container.addEventListener("mouseleave", onMouseLeave);
  
    document.getElementById("controls")?.setAttribute('data-cursor-pointer', true);
  
    const pointerHover = document.querySelectorAll("[data-cursor-pointer]"); 
    pointerHover.forEach((item) => {
      item.addEventListener('mouseover', onMouseOverPointer);
      item.addEventListener('mouseleave', onMouseLeavePointer);
    });
  }
}

function setCursor() {
  cursor = document.createElement("div");
  cursorCircle = document.createElement("div");
  cursorCircle.id = "cursor-circle";
  cursor.id = "cursor";
  cursor.innerText = "тяни";
  cursor.appendChild(cursorCircle);
  document.body.appendChild(cursor);
}

function onMouseEnter() {
  gsap.to(cursor, {
    scale: 1,
    ease: 'power2.out',
    duration: 0.5,
  });
}

function onMouseLeave() {
  gsap.to(cursor, {
    scale: 0,
    ease: 'power2.out',
    duration: 0.5,
  });
}

function onMouseDown() {
  gsap.to(cursorCircle, {
    scale: 1.3,
    overwrite: true,
    ease: 'power2.out',
    duration: 0.4,
  });
};

function onMouseMove(e) {
  gsap.to(cursor, {
    x: e.clientX,
    y: e.clientY,
    ease: 'power2.out',
    duration: 0.65,
  });
};

function onMouseUp() {
  gsap.to(cursorCircle, {
    scale: 1,
    overwrite: true,
    ease: 'power2.out',
    duration: 0.4,
  });
};

function onMouseOverPointer() {
  gsap.to(cursor, {
    scale: 0,
    ease: 'power2.out',
    duration: 0.2,
  });
}

function onMouseLeavePointer() {
  gsap.to(cursor, {
    scale: 1,
    ease: 'power2.out',
    duration: 0.2,
  });
}