let THREE;

let scene;
let camera;
let renderer;
let container;
let wrapper;
let stats;
let texs = {};
let texsVideo = [];
let planes = [];
let gui;
let phiOffsets = [];

let size = 1.0;
let maxISize = 60;
let maxN = 42;
let timer;

let isDragging = false;
let renderFlag = true;

const dragStart = { x: 0, y: 0 };
const targetRotation = { x: 0, y: 0 };
const saveRotation = { x: 0, y: 0 };
const targetCamera = { x: 0, z: 1 };

let state = {
  pixelRatio: Math.min(window.devicePixelRatio, 2),

  iSize: 15,
  radius: 4.0,
  N: 30,
  modParameter: 5.0,

  drag: true,
  dragVelocity: 0.1,
  dragIntensity: 0.002,

  scrollVelocity: 0.01,
  scrollIntensity: 0.005,

  offset: 0.0,

  randomize: false,
  delayRandomizeImage: 3,

  background: "#000000",
  backgroundCell: "#ffffff",

  inFlow: false,
  autoplayVideo: false,

  autoRotation: false,
  autoRotationVelocity: 0.05,

  rotationByArrows: false,
  rotationByArrowsIntensity: 0,

  cameraControl: false,
  cameraControlIntensity: 0,
  cameraControlVelocity: 0,

  videoPause: videoPause,
  videoPlay: videoPlay,
  saveState: saveState,
  loadState: loadState,
  applyState: applyState,
};

let data = null;
let dataItems = [];
let itemsPlaneMap = {};

let planeLoadedIndex = 0;
let textureNotLoadedIndex = 0;
let randomizeIntervals = [];
let loadedAllTextures = false;
let loadedFrustum = false;
let updated = false;

let tloader = null;

const cameraControl = {
  forward: false,
  backward: false,
  left: false,
  right: false,
  minZ: -3.5,
  maxZ: 20,
  maxX: 10,
  minX: -10,
};

const rotationByArrows = {
  forward: false,
  backward: false,
  left: false,
  right: false,
};

const input = document.createElement("input");

for (let t = 0; t < maxISize; t++) {
  phiOffsets.push(t % 2 == 0 ? 0.0 : 2.0 * Math.PI * state.offset);
}

function setup(
  initTHREE,
  initState,
  initData,
  containerElem,
  triggerElem,
  initStats = null,
) {
  THREE = initTHREE;
  state = {...state, ...initState};
  data = initData;
  maxN = Object.keys(initData).length;

  if (typeof createImageBitmap !== "unknown") {
    tloader = new THREE.ImageBitmapLoader();
    tloader.setOptions({ imageOrientation: "flipY" });
  } else {
    tloader = new THREE.ImageLoader();
  }

  container = containerElem;
  wrapper = triggerElem || containerElem;

  if (initStats) {
    stats = initStats();
    container.appendChild(stats.domElement);
  }

  setupScene();
  setupRenderer();
  setupEventListeners();
  setupCamera();
  setupLights();
  
  initializeUniqueItems();
  buildScene();
  draw();
}

function setGui(GUI) {
  gui = new GUI();

  gui
    .add(state, "radius", 0.5, 5)
    .listen()
    .onChange(() => {
      updateScene();
    });
  gui
    .add(state, "iSize", 3, maxISize, 1)
    .listen()
    .onChange(() => {
      updateScene();
    });

  gui
    .add(state, "N", 1, maxN, 1)
    .listen()
    .onChange(() => {
      if (timer) clearTimeout(timer);

      timer = setTimeout(() => {
        resetGlobals();
        initializeUniqueItems();

        scene.remove.apply(scene, scene.children);
        buildScene();
      }, 500);
    });

  gui.add(state, "modParameter", 1.0, 40.0, 1.0).listen();
  gui
    .add(state, "offset", 0, 1)
    .listen()
    .onChange(() => {
      updateScene();
    });
  gui
    .add(state, "randomize", true, false)
    .listen()
    .onChange((value) => {
      if (loadedAllTextures) {
        if (value) {
          setRandomizeImage();
        } else {
          removeRandomizeImage();
        }
      }
    });
  gui
    .add(state, "delayRandomizeImage", 1, 10, 0.5)
    .listen()
    .onChange((value) => {
      if (state.randomize && value) {
        removeRandomizeImage();
        setRandomizeImage();
      }
    });

  gui
    .addColor(state, "background")
    .listen()
    .onChange(() => { scene.background = new THREE.Color(state.background) });

  gui
    .addColor(state, "backgroundCell")
    .listen()
    .onChange(() => {
      planes.forEach(plane => {
        if (!plane.material.map) plane.material.color = new THREE.Color(state.backgroundCell);
      })
    });

  gui.add(state, "scrollVelocity", 0.0, 0.5, 0.005).listen();
  gui.add(state, "scrollIntensity", 0.0, 0.01, 0.001).listen();  

  gui.add(state, "drag").listen();
  gui.add(state, "dragVelocity", 0.0, 0.5, 0.005).listen();
  gui.add(state, "dragIntensity", 0.0, 0.01, 0.001).listen();

  gui
    .add(state, "rotationByArrows")
    .listen()
    .onChange((value) => {
      if (!value) {
        removeKeyboardEvents();
      }
      setupKeyboardEvents();
    });
  gui.add(state, "rotationByArrowsIntensity", 0.1, 2, 0.05).listen();

  gui
    .add(state, "cameraControl")
    .listen()
    .onChange((value) => {
      if (!value) {
        removeKeyboardEvents();
      }
      setupKeyboardEvents();
    });
  gui.add(state, "cameraControlIntensity", 0.1, 1, 0.05).listen();
  gui.add(state, "cameraControlVelocity", 0.1, 1, 0.05).listen();

  gui
    .add(state, "inFlow")
    .listen()
    .onChange((value) => {
      if (value) {
        window.addEventListener("scroll", onScroll);
        window.removeEventListener("wheel", onWheel);
      } else {
        window.removeEventListener("scroll", onScroll);
        window.addEventListener("wheel", onWheel);
      }
    });

  gui.add(state, "autoplayVideo");
  gui.add(state, "autoRotation");
  gui.add(state, "autoRotationVelocity", 0.01, 1, 0.01).listen();

  gui.add(state, "videoPause");
  gui.add(state, "videoPlay");
  gui.add(state, "saveState");
  gui.add(state, "loadState");
  gui.add(state, "applyState");

  container.appendChild(gui.domElement);
}

function saveState() {
  const a = document.createElement("a");
  const stateToExport = { ...state };
  let file = new Blob([JSON.stringify(stateToExport)], {
    type: "text/plain",
  });
  a.href = URL.createObjectURL(file);
  a.download = "state.json";
  a.click();
}

function loadState() {
  input.type = "file";
  input.click();
}

function applyState() {
  if (input.files && input.files.length > 0) {
    input.files[0].text().then((res) => {
      let newState = JSON.parse(res);
      readState(newState, state);

      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        resetGlobals();
        initializeUniqueItems();
        
        scene.remove.apply(scene, scene.children);
        buildScene();
      }, 500);
    });
  }
}

function readState(newState, state) {
  const badNames = [];

  const recursiveStateUpdate = (inState, toState) => {
    if (Array.isArray(toState)) {
      for (let i = 0; i < toState.length; i++) {
        if (typeof toState[i] === 'object')
          recursiveStateUpdate(inState[i], toState[i]);
        else toState[i] = inState[i];
      }
    } else {
      Object.keys(toState).forEach((key) => {
        if (badNames.indexOf(key) !== -1) return;
        if (
          typeof inState[key] !== 'undefined' &&
          typeof inState[key] !== 'object'
        ) {
          toState[key] = inState[key];
        } else if (inState[key] && typeof inState[key] === 'object') {
          recursiveStateUpdate(inState[key], toState[key]);
        }
      });
    }
  };

  recursiveStateUpdate(newState, state);
}

function setupEventListeners() {
  wrapper.addEventListener("mousemove", onMouseMove);
  wrapper.addEventListener("mousedown", onMouseDown);
  wrapper.addEventListener("mouseup", onMouseUp);

  wrapper.addEventListener("touchstart", onTouchStart);
  wrapper.addEventListener("touchend", onTouchEnd);
  wrapper.addEventListener("touchmove", onTouchMove);

  window.addEventListener("resize", resize);

  if (state.inFlow) {
    window.addEventListener("scroll", onScroll);
  } else {
    window.addEventListener("wheel", onWheel);
  }

  setupKeyboardEvents();
}

function setupKeyboardEvents() {
  if (state.rotationByArrows || state.cameraControl) {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
  }
}

function removeKeyboardEvents() {
  window.removeEventListener("keydown", onKeyDown);
  window.removeEventListener("keyup", onKeyUp);
}

function onKeyDown(e) {
  if (state.rotationByArrows) {
    switch (e.code) {
      case "ArrowUp":
        rotationByArrows.forward = true;
        break;
      case "ArrowDown":
        rotationByArrows.backward = true;
        break;
      case "ArrowLeft":
        rotationByArrows.left = true;
        break;
      case "ArrowRight":
        rotationByArrows.right = true;
        break;  
    }
  }

  if (state.cameraControl) {
    switch (e.code) {
      case "KeyW":
        cameraControl.forward = true;
        break;
      case "KeyS":
        cameraControl.backward = true;
        break;
      case "KeyA":
        cameraControl.left = true;
        break;
      case "KeyD":
        cameraControl.right = true;
        break;
    }  
  }
}

function onKeyUp(e) {
  if (state.rotationByArrows) {
    switch (e.code) {
      case "ArrowUp":
        rotationByArrows.forward = false;
        break;
      case "ArrowDown":
        rotationByArrows.backward = false;
        break;
      case "ArrowLeft":
        rotationByArrows.left = false;
        break;
      case "ArrowRight":
        rotationByArrows.right = false;
        break;
    }
  }

  if (state.cameraControl) {
    switch (e.code) {
      case "KeyW":
        cameraControl.forward = false;
        break;
      case "KeyS":
        cameraControl.backward = false;
        break;
      case "KeyA":
        cameraControl.left = false;
        break;
      case "KeyD":
        cameraControl.right = false;
        break;
    }
  }
}

function updateScene() {
  updated = true;
  planes = [];

  scene.remove.apply(scene, scene.children);
  buildScene();

  dataItems.forEach((item) => {
    setTexture(item.itemIndex);
  });
}

function onMouseDown(e) {
  if (!state.drag || isDragging) return;
  isDragging = true;

  dragStart.x = e.clientX;
  dragStart.y = e.clientY;

  saveRotation.y = scene.rotation.y;
  saveRotation.x = scene.rotation.x;
};

function onMouseMove(e) {
  if (!state.drag || !isDragging) return;

  targetRotation.y = saveRotation.y + (dragStart.x - e.clientX) * state.dragIntensity;
  targetRotation.x = saveRotation.x + (dragStart.y - e.clientY) * state.dragIntensity;
};

function onMouseUp() {
  if (!state.drag || !isDragging) return;
  isDragging = false;
};

function onWheel(e) {
  targetRotation.y = scene.rotation.y + e.wheelDelta * state.scrollIntensity;
}

let saveScroll = 0;
function onScroll() {
  const boundingRect = wrapper.getBoundingClientRect();

  if (boundingRect.top <= boundingRect.height && boundingRect.top >= -boundingRect.height) {
    const offset = (boundingRect.height - boundingRect.top) * state.scrollIntensity;
    const diff = saveScroll - offset;
    saveScroll = offset;

    targetRotation.y = scene.rotation.y - diff;
  }
}

function onTouchStart(e) {
  if (!state.drag || isDragging) return;
  isDragging = true;

  dragStart.x = e.touches[0].clientX;
  dragStart.y = e.touches[0].clientY;

  saveRotation.y = scene.rotation.y;
  saveRotation.x = scene.rotation.x;
}

function onTouchMove(e) {
  if (!state.drag || !isDragging) return;

  targetRotation.y = saveRotation.y + (dragStart.x - e.touches[0].clientX) * state.dragIntensity;
  targetRotation.x = saveRotation.x + (dragStart.y - e.touches[0].clientY) * state.dragIntensity;
}

function onTouchEnd() {
  if (!isDragging) return;
  isDragging = false;
};

function resize() {
  camera.aspect = container.offsetWidth / container.offsetHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(container.offsetWidth, container.offsetHeight);
}

function videoPause() {
  dataItems.forEach((item) => {
    if (item.type === "video" && item.loaded) {
      const playPromise = item.videoElem.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          item.videoElem.pause();
        })
      }
    }
  });
};

function videoPlay() {
  dataItems.forEach((item, index) => {
    if (item.type === "video" && item.loaded) {
      setTimeout(() => {
        item.videoElem.play();
      }, 100 * index);
    }
  });
};

function buildScene() {
  let iSize = state.iSize;
  size = (2.0 * Math.PI) / iSize;

  const frustum = new THREE.Frustum();
  const matrix = new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse)
  frustum.setFromProjectionMatrix(matrix);

  for (let t = 0; t < maxISize; t++) {
    if (t % 2 != 0) phiOffsets[t] = 2.0 * Math.PI * state.offset;
  }

  let k = 0;
  for (let i = 0; i <= iSize - 1; i++) {
    for (let j = 0; j <= iSize - 1; j++) {
      let phi = (phiOffsets[j] + i * size) % (2.0 * Math.PI);
      let theta = (size / 4.0 + (j * size) / 2.0) % Math.PI;

      let rad = state.radius;

      let h = rad * Math.sin(theta);

      const width = 0.8 * h * size;
      const height = width * 9 / 16;

      let plane = new THREE.Mesh(
        new THREE.PlaneGeometry(width, height),
        new THREE.MeshBasicMaterial({
          color: new THREE.Color(state.backgroundCell),
          map: null,
          side: THREE.FrontSide,
        }),
      );
      plane.position.x = rad * Math.sin(theta) * Math.cos(phi); //i * size;
      plane.position.y = rad * Math.cos(theta); //j * size;
      plane.position.z = rad * Math.sin(theta) * Math.sin(phi);

      plane.lookAt(new THREE.Vector3(0, 0, 0));

      plane.frustum = frustum.containsPoint(plane.position);
      plane.frustumCulled = true;
      plane.itemIndex = k % state.N;

      planes.push(plane);
      scene.add(plane);

      if (!itemsPlaneMap[plane.itemIndex]) {
        const infoPlane = {
          planes: [plane],
          data: dataItems[plane.itemIndex],
        }
        itemsPlaneMap[plane.itemIndex] = infoPlane;
      } else {
        itemsPlaneMap[plane.itemIndex].planes.push(plane);
      }

      k++;
    }
  }

  if (!loadedFrustum) {
    const planesFrustum = shuffleArray(planes.filter(plane => plane.frustum));
    initTexturesPlanes(planesFrustum);
  }
}

function initTexturesPlanes(planeList) {
  const plane = planeList[planeLoadedIndex];
  const itemIndex = plane.itemIndex;
  const itemData = dataItems.find((item) => itemIndex === item.itemIndex);
  const src = typeof itemData.src === "string" ? itemData.src : itemData.src[0];
  const nameTexture = "texture" + itemIndex;

  if (!texs[nameTexture]) {
    if (itemData.type === "video") {
      const video = itemData.videoElem;
      video.src = src;
      video.addEventListener("loadeddata", function() {
        video.width = this.videoWidth;
        video.height = this.videoHeight;
        itemData.loaded = true;
        texs[nameTexture] = createTextureFromVideoElement(video);
        texsVideo.push(texs[nameTexture]);
        if (state.autoplayVideo) video.play();
        setTexture(itemIndex);
        next();
      });
    } else  {
      tloader.load(src, (img) => {
        itemData.loaded = true;
        texs[nameTexture] = createTextureFromImage(img);
        setTexture(itemIndex);
        next();
      });
    }
  } else {
    setTexture(itemIndex);
    next();
  }
 
  function next() {
    if (planeLoadedIndex < planeList.length - 1) {
      planeLoadedIndex++;
      initTexturesPlanes(planeList);
    } else {
      loadedFrustum = true;
      const texturesNotLoaded = dataItems.filter((item) => !item.loaded);
      if (texturesNotLoaded.length) {
        loadTextures(texturesNotLoaded);
      } else {
        loadedTextures();
      }
    }
  }
}

function loadTextures(texturesNotLoaded) {
  const itemIndex = texturesNotLoaded[textureNotLoadedIndex].itemIndex;
  const itemData = dataItems.find((item) => itemIndex === item.itemIndex);
  const src = typeof itemData.src === "string" ? itemData.src : itemData.src[0];
  const nameTexture = "texture" + itemIndex;

  if (itemData.type === "video") {
    const video = itemData.videoElem;
    video.src = src;
    video.addEventListener("loadeddata", function() {
      video.width = this.videoWidth;
      video.height = this.videoHeight;
      itemData.loaded = true;
      texs[nameTexture] = createTextureFromVideoElement(video);
      texsVideo.push(texs[nameTexture]);
      if (state.autoplayVideo) video.play();
      setTexture(itemIndex);
      nextLoad();
    });
  } else {
    tloader.load(src, (img) => {
      itemData.loaded = true;
      texs[nameTexture] = createTextureFromImage(img);
      setTexture(itemIndex);
      nextLoad();
    });
  }

  function nextLoad() {
    if (textureNotLoadedIndex < texturesNotLoaded.length - 1) {
      textureNotLoadedIndex++;
      loadTextures(texturesNotLoaded);
    } else {
      loadedTextures();
    }
  }
}

function loadedTextures() {
  loadedAllTextures = true;

  if (state.randomize) {
    setRandomizeImage();
  }

  if (updated) {
    updated = false;
    dataItems.forEach((item) => {
      setTexture(item.itemIndex);
    });
  }
}

function setTexture(index) {
  planes.forEach((plane) => {
    if (index === plane.itemIndex) {
      const nameTexture = "texture" + plane.itemIndex;      
      const texture = texs[nameTexture];

      if (texture) {
        texture.matrixAutoUpdate = false;
  
        const planeAspect = plane.geometry.parameters.width / plane.geometry.parameters.height;
        const imageAspect = texture.image.width / texture.image.height;
        if (planeAspect < imageAspect) {
          texture.matrix.setUvTransform(0, 0, planeAspect / imageAspect, 1, 0, 0.5, 0.5);
        } else {
          texture.matrix.setUvTransform(0, 0, 1, imageAspect / planeAspect, 0, 0.5, 0.5);
        }
    
        const material = new THREE.MeshBasicMaterial({
          map: texture,
          side: THREE.FrontSide,
        });
    
        material.onBeforeCompile = function (shader) {
          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <map_fragment>',
              `
              #ifdef USE_MAP
                vec4 sampledDiffuseColor = texture2D( map, vMapUv );
                // inline sRGB decode
                sampledDiffuseColor = vec4( mix( pow( sampledDiffuseColor.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), sampledDiffuseColor.rgb * 0.0773993808, vec3( lessThanEqual( sampledDiffuseColor.rgb, vec3( 0.04045 ) ) ) ), sampledDiffuseColor.a );
                diffuseColor *= sampledDiffuseColor;
              #endif
              `
          );
        };
    
        plane.material = material;
      }
    }
  });
}

function createTextureFromVideoElement(video) {
  let texture = new THREE.Texture(video);
  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;
  texture.format = THREE.RGBAFormat;
  // texture.colorSpace = THREE.SRGBColorSpace;
  texture.needsUpdate = true;
  return texture;
}

function createTextureFromImage(img) {
  const texture = new THREE.CanvasTexture(img);
  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;
  texture.format = THREE.RGBAFormat;
  texture.needsUpdate = true;
  return texture;
}

function shuffleArray(array) {
  let newArr = [...array];
  for (let i = newArr.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
  }
  return newArr;
}

function resetGlobals() {
  dataItems.forEach((item, i) => {
    if (item.type === "video") {
      const video = item.videoElem;
      video.addEventListener("playing", () => {
        video.pause();
        video.removeAttribute("src");
        video.remove();
      });

      setTimeout(() => {
        if (video) {
          video.removeAttribute("src");
          video.remove();
        }
      }, 500);
    }
    delete dataItems[i];
  });

  dataItems = [];
  planes = [];
  texs = {};
  texsVideo = [];
  loadedFrustum = false;
  loadedAllTextures = false;
  planeLoadedIndex = 0;
  textureNotLoadedIndex = 0;
}

function initializeUniqueItems() {
  for (let i = 0; i < state.N; i++) {
    const key = Object.keys(data)[i];
    const dataItem = data[key];
    dataItem.itemIndex = i;
    dataItem.loaded = false;
    if (dataItem.type === "video") {
      dataItem.videoElem = setUpVideo(dataItem, i);
    }
    dataItems.push(dataItem);
  }
}

function setupScene() {
  scene = new THREE.Scene();
  scene.background = new THREE.Color(state.background);

  if (state.inFlow) {
    const boundingRect = wrapper.getBoundingClientRect();
    if (boundingRect.top <= boundingRect.height && boundingRect.top >= -boundingRect.height) {
      const offset = (boundingRect.height - boundingRect.top) * state.scrollIntensity;
      const diff = saveScroll - offset;
      saveScroll = offset;
  
      scene.rotation.y = diff;
      targetRotation.y = diff;
    }
  }
}

function setupCamera() {
  let res = container.offsetWidth / container.offsetHeight;
  camera = new THREE.PerspectiveCamera(75, res, 0.1, 1000);
  camera.position.z = 1;
  camera.position.y = 0;
  camera.position.x = 0;
}

function setUpVideo(data) {
  const videlem = document.createElement("video");
  const sourceMP4 = document.createElement("source");
  sourceMP4.type = "video/mp4";
  videlem.appendChild(sourceMP4);
  videlem.autoplay = true;
  videlem.preload = "auto";
  videlem.muted = true;
  videlem.playsInline = true;
  videlem.crossOrigin = "anonymous";
  videlem.style.display = "none";

  videlem.addEventListener("ended", () => {
    if (loadedAllTextures) {
      setRandomize(data.itemIndex);
    }

    if (data) videlem.play();
  });

  return videlem;
}

function setRandomizeImage() {
  dataItems.forEach((item) => {
    if (item.type === "image") {
      const interval = setInterval(() => {
        setRandomize(item.itemIndex);
      }, state.delayRandomizeImage * 1000);
      randomizeIntervals.push(interval);
    }
  });
}

function removeRandomizeImage() {
  randomizeIntervals.forEach((interval) => {
    clearInterval(interval);
  });
  randomizeIntervals = [];
}

function setRandomize(currentIndex) {
  if (state.randomize) {
    let currentPlanes = itemsPlaneMap[currentIndex].planes;

    for (let i = 0; i < currentPlanes.length; i++) {
      let plane = currentPlanes.shift();

      let randomIndex = Math.floor(state.N * Math.random());
      let newItem = dataItems[randomIndex];
      let newIndex = newItem.itemIndex;

      plane.material.map = texs["texture" + newIndex];

      if (itemsPlaneMap[newIndex]) {
        itemsPlaneMap[newIndex].planes.push(plane);
      }
    }
  }
}

function setupRenderer() {
  renderer = new THREE.WebGL1Renderer({ antialias: true });
  renderer.setSize(container.offsetWidth, container.offsetHeight);
  renderer.setPixelRatio(state.pixelRatio);
  container.appendChild(renderer.domElement);
}

function setPixelRatio(pixelRatio) {
  renderer.setPixelRatio(pixelRatio);
}

function setupLights() {
  let ambientLight = new THREE.AmbientLight(0x0c0c0c);
  scene.add(ambientLight);

  let spotLight = new THREE.SpotLight(0xffffff);
  spotLight.position.set(-30, 60, 60);
  spotLight.castShadow = true;
  scene.add(spotLight);
}

let r = 0;
let previous = Date.now();

function draw() {
  if (!renderFlag) return;

  requestAnimationFrame(draw);

  let current = Date.now();
  let dt = Math.min(current - previous, 60);

  stats?.begin();

  if (state.autoRotation) {
    scene.rotation.y -= dt / 1000 * state.autoRotationVelocity;
  } else {
    const velocity = isDragging ? state.dragVelocity : state.scrollVelocity;
    scene.rotation.y += velocity * dt / 10 * (targetRotation.y - scene.rotation.y);
    scene.rotation.x += velocity * dt / 10 * (targetRotation.x - scene.rotation.x);
  }

  camera.position.x += state.cameraControlVelocity * dt / 100 * (targetCamera.x - camera.position.x);
  camera.position.z += state.cameraControlVelocity * dt / 100 * (targetCamera.z - camera.position.z);

  texsVideo.forEach((tex, i) => {
    if (tex && i % state.modParameter == r) tex.needsUpdate = true;
  });
  r = (r + 1) % state.modParameter;

  if (!state.autoRotation && state.rotationByArrows) {
    updaterotationByArrows();
  }

  if (state.cameraControl) {
    updateCameraControl();
  }

  renderer.render(scene, camera);

  stats?.end();

  previous = current;
}

function updaterotationByArrows() {
  if (rotationByArrows.forward) {
    targetRotation.x = scene.rotation.x - state.rotationByArrowsIntensity;
  }
  if (rotationByArrows.backward) {
    targetRotation.x = scene.rotation.x + state.rotationByArrowsIntensity;
  }
  if (rotationByArrows.left) {
    targetRotation.y = scene.rotation.y - state.rotationByArrowsIntensity;
  }
  if (rotationByArrows.right) {
    targetRotation.y = scene.rotation.y + state.rotationByArrowsIntensity;
  }
}

function updateCameraControl() {
  if (cameraControl.forward) {
    targetCamera.z = Math.max(camera.position.z - state.cameraControlIntensity, cameraControl.minZ);
  }
  if (cameraControl.backward) {
    targetCamera.z = Math.min(camera.position.z + state.cameraControlIntensity, cameraControl.maxZ);
  }
  if (cameraControl.left) {
    targetCamera.x = Math.max(camera.position.x - state.cameraControlIntensity, cameraControl.minX);
  }
  if (cameraControl.right) {
    targetCamera.x = Math.min(camera.position.x + state.cameraControlIntensity, cameraControl.maxX);
  }
}

function toggleRender(value) {
  renderFlag = value;

  if (renderFlag) {
    draw();
  }
}

function updateScale({ radius, iSize, offset }) {
  state.radius = radius || state.radius;
  state.iSize = iSize || state.iSize;
  state.offset = offset || state.offset;

  updateScene();
}

window.videoapp = {
  setup: setup,
  setGui: setGui,
  toggleRender: toggleRender,
  videoPlay: videoPlay,
  videoPause: videoPause,
  resize: resize,
  updateScale: updateScale,
  setPixelRatio: setPixelRatio,
}