import * as THREE from "three";

import "./common/module.js";
import cursor from "./common/cursor.js";
import isWebp from "./common/isWebp.js";
import config from "./config/main.js";

const scales = [
  {
    radius: 4.0,
    iSize: 15,
    offset: 0.0,
  },
  {
    radius: 4.25,
    iSize: 20,
    offset: 0.0,
  },
  {
    radius: 5.0,
    iSize: 25,
    offset: 0.1,
  },
];
const maxScale = scales.length - 1;
let scaleCurrent = 0;

const container = document.getElementById("container");
const controlPlus = document.querySelector(".control--plus");
const controlMinus = document.querySelector(".control--minus");

init();

async function init() {
  const domain = "https://reel.design.big3.ru";
  const response = await fetch(domain + "/data/fallback-data.json");
  const json = await response.json();
  const data = {};
  let quantityItems = 0;

  for (let key in json.items) {
    const item = json.items[key];
    const isSkipped = json.videoappSkipIds?.some(skipItem => skipItem === key) || false;

    if (!isSkipped) {
      let src = item.src;
      if (Array.isArray(item.src)) {
        src = item.src[0];
      }

      if (item.type === "image") {
        if (!isWebp && item.fallback?.main?.default) {
          src = item.fallback.main.default;
        }

        src = src.replace("images/", "images/min/");
      } else {
        src = src.replace("videos/", "videos/min/");
      }

      item.src = domain + src;

      data[key] = item;
      
      quantityItems++;
    }
  }

  config.N = quantityItems;

  window.videoapp.setup(
    THREE,
    config,
    data,
    container
  );
  
  cursor();
  setControls();
}

function setControls() {
  controlPlus.addEventListener("click", scalePrev);
  controlMinus.addEventListener("click", scaleNext);

  if (scaleCurrent === 0) {
    controlPlus.setAttribute('disabled', true);
  } else if (scaleCurrent === maxScale) {
    controlMinus.setAttribute('disabled', true);
  }
}

function scaleNext() {
  if (scaleCurrent < maxScale) {
    scaleCurrent++;
    changeScale();
  }
}

function scalePrev() {
  if (scaleCurrent > 0) {
    scaleCurrent--;
    changeScale();
  }
}

function changeScale() {
  if (scaleCurrent === 0) {
    controlPlus.disabled = true;
    controlMinus.disabled = false;
  } else if (scaleCurrent === maxScale) {
    controlPlus.disabled = false;
    controlMinus.disabled = true;
  } else {
    controlPlus.disabled = false;
    controlMinus.disabled = false;
  }

  window.videoapp.updateScale({
    radius: scales[scaleCurrent].radius,
    iSize: scales[scaleCurrent].iSize,
    offset: scales[scaleCurrent].offset,
  });
}