const config = {
  iSize: 15,
  radius: 4.0,
  N: 30,
  modParameter: 5.0,

  drag: true,
  dragVelocity: 0.1,
  dragIntensity: 0.002,

  scrollVelocity: 0.01,
  scrollIntensity: 0.005,

  offset: 0.0,

  randomize: false,
  delayRandomizeImage: 3,

  background: '#000000',
  backgroundCell: '#ffffff',

  inFlow: false,
  autoplayVideo: true,

  rotationByArrows: true,
  rotationByArrowsIntensity: 0.3,

  cameraControl: true,
  cameraControlIntensity: 0.5,
  cameraControlVelocity: 0.5,
};

export default config;